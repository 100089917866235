export function parseSparqlParams(query: string): string[] {
  let params: string[] = []
  let inSelect = false
  let inParentheses = 0
  let lastAliasParam: string | null = null
  let insideAlias = false

  const tokens = query.match(/\(|\)|\?[\w]+|as|\*|\w+|["'][^"']+["']|SELECT|WHERE|{|}/gi)

  if (!tokens) return []

  for (const token of tokens) {
    if (token.toUpperCase() === 'SELECT') {
      inSelect = true
      continue
    }

    if (token.toUpperCase() === 'WHERE' || token === '{') {
      break
    }

    if (inSelect) {
      if (token === '*') {
        return []
      }
      if (token === '(') {
        inParentheses++
        if (!insideAlias) {
          insideAlias = true
          lastAliasParam = null
        }
      } else if (token === ')') {
        inParentheses--
        if (inParentheses === 0) {
          insideAlias = false
          if (lastAliasParam) {
            params.push(lastAliasParam)
          }
        }
      } else if (token.startsWith('?')) {
        const param = token.replace('?', '')
        if (insideAlias) {
          lastAliasParam = param
        } else {
          params.push(param)
        }
      }
    }
  }

  return params
}

// Poor man's testing framework
const runTest = false
if (runTest) {
  const cases = [
    {
      query: 'SELECT ("user" as ?user) (?someone as ?friend) ?something WHERE { ?s ?p ?o . }',
      expected: 'user friend something'
    },
    {
      query: `
      SELECT ?one ?two ?three
      ?four { ?five ?six ?seven . }`,
      expected: 'one two three four'
    },
    {
      query: 'SELECT (?var as ?alias) ?realParam WHERE { ?s ?p ?o . }',
      expected: 'alias realParam'
    },
    {
      query: 'select (?another as ?a) ?b ?c { { ?s1 ?p1 ?o1 . } union { ?s2 ?p2 ?o2 . }}',
      expected: 'a b c'
    },
    {
      query: 'select * where { ?s ?p ?o . } ',
      expected: ''
    }
  ]

  for (let i = 0; i < cases.length; i++) {
    const test = cases[i]
    const actual = parseSparqlParams(test.query).join(' ');
    const passed = actual === test.expected;
    console.log(`parseSparqlParams test ${i + 1} ${passed ? 'PASS' : 'FAIL'}`, {
      query: test.query,
      expected: test.expected,
      actual: actual
    })
  }
}
